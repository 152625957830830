<template>
  <div>
    <h1 style="text-align: center">Har文件精简工具</h1>
    <el-upload
        drag
        :limit="1"
        ref="upload"
        action=""
        :auto-upload="false"
        accept=".har, .json"
        :file-list="fileList"
        :show-file-list="false"
        style="text-align: center"
        :on-change="onSuccess">
      <em class="el-icon-upload"></em>
      <div class="el-upload__text">将 *.har 文件拖到此处，或<em>点击上传</em></div>
      <div class="el-upload__tip" slot="tip">上传 har 文件，且只能上传 1 个文件</div>
    </el-upload>
    <br/>
    <div style="display: flex">
      <el-button style="width: 100%; max-width: 360px; margin: 0 auto" type="primary" @click="save">
        导出为 mock.har
      </el-button>
    </div>
    <br/>
    <br/>
    <template v-if="uploadData">
      <template v-for="(entry,index) in uploadData.log.entries">
        <el-checkbox
            :key="index"
            style="white-space:pre; font-family: Consolas, Monaco, monospace;"
            :checked="selectIndex.has(index)"
            @change="check => checkChanged(check, entry,index)"
            :label="`${entry.response.status} | ${entry.request.method.padEnd(7)}| ${entry.request.url}`">
        </el-checkbox>
        <br :key="index"/>
      </template>
    </template>
  </div>
</template>

<script>
export default {
  data() {
    return {
      fileList: [],
      uploadData: undefined,
      selectIndex: new Set()
    }
  },
  methods: {
    onSuccess(res, file) {
      console.log(res)
      console.log(file)
      let reader = new FileReader()
      reader.readAsText(file[0].raw)
      reader.onload = ((e) => {
        this.uploadData = JSON.parse(e.target.result)
        console.log(this.uploadData)
      })
    },
    checkChanged(check, entry, index) {
      console.log(check, entry, index)
      if (check) {
        this.selectIndex.add(index)
      } else {
        this.selectIndex.delete(index)
      }
    },
    save() {
      // 把数据发送给 父元素
      console.log('导出')
      if (!this.uploadData) {
        this.$message.warning('没有数据可以导出')
        return
      }
      const resData = JSON.parse(JSON.stringify(this.uploadData))
      resData.log.entries = resData.log.entries
          .filter((_, index) => this.selectIndex.has(index))
          // .map(entry => {
          //   // 移除不关心的数据
          //   if (entry._initiator) delete entry._initiator
          //   delete entry.request.headers
          //   delete entry.response.headers
          //   return entry
          // })
      this.exportFile(JSON.stringify(resData, null, 2), 'mock.har')
    },
    exportFile(data, fileName) {
      // 声明blob对象
      const streamData = new Blob([data], { type: 'application/octet-stream' })
      // ie || edge 浏览器
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(streamData, fileName)
      } else {
        // 创建隐藏的可下载链接
        const link = document.createElement('a')
        // 下载文件名称
        link.download = fileName
        link.style.display = 'none'
        // 字符内容转变为blob地址
        link.href = window.URL.createObjectURL(streamData)
        // 触发点击
        document.body.appendChild(link)
        link.click()
        // 移除
        document.body.removeChild(link)
      }
    }
  }
}
</script>
